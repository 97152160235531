var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Date")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",staticStyle:{"background":"transparent"},attrs:{"config":{
                  enableTime: false,
                  dateFormat: 'd/m/Y',
                  maxDate: new Date(),
                }},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Ref. No")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Ref. No"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"placeholder":"Enter Ref. No"},model:{value:(_vm.refno),callback:function ($$v) {_vm.refno=$$v},expression:"refno"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Department")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Department"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"placeholder":"None","options":_vm.departmentoption},model:{value:(_vm.department),callback:function ($$v) {_vm.department=$$v},expression:"department"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Name")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"placeholder":"Enter Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("File")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"File"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":"Select File","drop-placeholder":"Drop file here...","accept":"image/*"},on:{"input":function($event){return _vm.handleFile($event, 'document')}},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('attachment',{attrs:{"data":_vm.file}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v("Description")]),_c('b-form-textarea',{attrs:{"placeholder":"Description","rows":"6"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('br'),_c('b-button',{staticClass:"mr-4",attrs:{"variant":"primary","type":"submit","disabled":_vm.toggle},on:{"click":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_vm._v(" Submit ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.onClickBack}},[_vm._v("Back ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }